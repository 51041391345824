import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import img from "../images/dale-banner-face.jpg"

export default function ImageLeftSection() {
  let reversed = false;
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ")
  }
  return (
      <div className="relative py-16 overflow-x-hidden face-gray-bg sm:py-6" id="face">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div
            className={classNames(
              reversed ? "order-2" : "",
              "relative sm:py-16 lg:py-0"
            )}
          >
            <div
              aria-hidden="true"
              className={classNames(
                reversed ? "lg:left-0" : "lg:right-0",
                "hidden sm:block lg:absolute lg:w-screen lg:inset-y-0 "
              )}
            >
              <div
                className={classNames(
                  reversed
                    ? "left-1/2 lg:left-72"
                    : "right-1/2 lg:right-72",
                  "absolute inset-y-0 w-full bg-gray-50"
                )}
              />
            </div>
            <div className="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              {/* Testimonial card*/}
              <div className="relative pb-10 overflow-hidden shadow-xl pt-[65.75%]">
                  <img
                    className="absolute inset-0 object-cover w-full h-full"
                    src={img}
                    alt="FACE hosted by Dale “Coach” Pinkert"
                  />

              </div>
            </div>
          </div>

          <div className="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-0">
            {/* Content area */}
            <div className="pt-12 sm:pt-16 lg:pt-20">
                <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-gray-900">
                  F.A.C.E. Show
                </h2>
                <p className="mt-4 text-lg text-gray-900">
                  <b>The F.A.C.E show is hosted by <a href="/our-team" className="face-blue hover:underline">Dale “Coach” Pinkert</a> every weekday at 8am ET (1pm UK time)</b>
                </p>
                <p className="mt-4 text-lg text-gray-900">
                  It lasts for one hour and <b>it’s free for anyone to join.</b> Dale and the ForexAnalytix team spend the first 30 minutes presenting their market analysis & insights, taking questions from participants when possible.
                </p>
                <p className="mt-4 text-lg text-gray-900">
                  The final 30 minutes are usually taken by <b>guest speaker presentations</b>. Guests will typically include traders, market analysts, academics, authors and other market participants.
                </p>
            </div>

            <div className="mt-8 overflow-hidden">
              <dl className="-mx-8 -mt-8 flex flex-wrap">
                <div className="flex flex-col px-8 pt-8">
                  <dt className="order-2 text-base font-medium text-gray-500">Shows</dt>
                  <dd className="order-1 text-2xl font-extrabold face-blue sm:text-3xl">1,100+</dd>
                </div>
                <div className="flex flex-col px-8 pt-8">
                  <dt className="order-2 text-base font-medium text-gray-500">Interviews</dt>
                  <dd className="order-1 text-2xl font-extrabold face-blue sm:text-3xl">700+</dd>
                </div>
                <div className="flex flex-col px-8 pt-8">
                  <dt className="order-2 text-base font-medium text-gray-500">Guest speakers</dt>
                  <dd className="order-1 text-2xl font-extrabold face-blue sm:text-3xl">400+</dd>
                </div>
              </dl>
            </div>

          </div>
        </div>
      </div>
    )
}
