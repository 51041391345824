import React, { useState, useEffect } from "react"
import pboockvar from "../images/twitter/pboockvar.png"
import jackschwager from "../images/twitter/jackschwager.jpg"
import petergoodburn from "../images/twitter/petergoodburn.jpg"
import marcmakingsense from "../images/twitter/marcmakingsense.png"
import stocktonkatie from "../images/twitter/stocktonkatie.jpg"
import fx_button from "../images/twitter/fx_button.png"
import LukeGromen from "../images/twitter/LukeGromen.jpg"
import hmeisler from "../images/twitter/hmeisler.jpg"
import JustinHertzberg from "../images/twitter/JustinHertzberg.jpeg"

const twIcon = () => {
  return (
    <svg fill="currentColor" viewBox="0 0 24 24" className="inline-block w-4 h-4 mr-1 face-twitter">
      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
    </svg>
  )
}

export default function Testimonials() {
  const [state, setState] = useState(false);

  useEffect(() => {
    import('tw-elements');
    setState({

      "Peter Goodburn": "Dale is the ultimate wisdom-keeper of financial markets – during many occasions he’s interviewed me and when I’ve watched his other interviews, he’s always amazed in the depth of his experience and knowledge of how markets function, how current price development occurs across all the asset classes – but more than that, his humane and ineffable character gets the most out of his guests – I’m a huge fan!",
      "Peter Goodburn_short": "Dale is the ultimate wisdom-keeper of financial markets – during many occasions he’s interviewed me and when I’ve watched ",
      "marcmakingsense": "I  have been interviewed by quite smart journalists and anchors on TV.  At their best, the interviews are informative and educational.  However, when Dale Pinkert \"interviews\" me, it is quite a different experience.  It is not so much Q&A exercise, but a discussion between two thoughtful people who have wrestled with the markets for more years than they care to admit.   Our scar tissues  can generate insight, but also humility.  If our species is going to evolve, which there is not guarantee, it is because we can learn from others' mistakes.  How to balance that with the confidence needed to trade is what Dale does best.",
      "marcmakingsense_short": "I have been interviewed by quite smart journalists and anchors on TV.  At their best, the interviews are informative and educational.  However",
      "fx_button": "One of the best ways to understand what’s happening in markets is to talk to other people and there is no one better to talk with than Dale Pinkert. Every interview with Dale over many years has been a wonderful opportunity to talk about markets but also to learn from one of the best. Dale’s curiosity, openness, knowledge and interviewing skill have attracted loyal followers around the world and I’m one of them.",
      "fx_button_short": "One of the best ways to understand what’s happening in markets is to talk to other people and there is no one better to talk with than Dale Pinkert. Every interview",
      "justin": "Over the years, I've have the pleasure of being interviewed by Dale Pinkert with Forex Analytix. Forex Analytix seeks to provide market insights and ideas to its audience on a daily basis. As its moderator and interviewer, Dale is able to deftly steer conversations with his interviewees to extract the views, opinions, ideas and outlooks that help the Forex Analytix audience to think critically about market drivers and investment decisions. While challenging, Dale is thoughtful and conscientious and as an interviewer and I always look forward to speaking with him and sharing my thoughts with the Forex Analytix membership.",
      "justin_short": "Over the years, I've have the pleasure of being interviewed by Dale Pinkert with Forex Analytix. Forex Analytix seeks to provide market insights and ideas to its audience on a daily basis. As its moderator and interviewer",
    })
  }, []);

  const trunc =(person) => {
    if (state[person] == undefined) return ""
    if (state[person+"_short"])
      if (!state[person+"_show_all"]) {
        return (
        <span>
          {state[person+"_short"]} ...&nbsp;
          <a className="underline text-sm italic" href="javascript:void(0)"
            onClick={()=>setState({...state, [person+"_show_all"]: !state[person+"_show_all"]})}>[show more]</a>
        </span> )
      } else {
        return (
        <span>
          {state[person]} &nbsp;
          <a className="underline text-sm italic" href="javascript:void(0)"
            onClick={()=>setState({...state, [person+"_show_all"]: !state[person+"_show_all"]})}>[show less]</a>
        </span> )
      }
    else
      return state[person]
  }

  return (
    <div className="face-gray-bg" id='testimonials'>
      <section className="max-w-7xl mx-auto py-16 px-4 sm:py-18 sm:px-6 lg:px-8 mb-0 text-gray-700">
          <div className="mb-16 text-center md:max-w-xl lg:max-w-3xl mx-auto">
            <h3 className="text-3xl font-bold mb-6 text-gray-900">TESTIMONIALS</h3>
            {/*<p className="mb-6 pb-2 md:mb-12 md:pb-0">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam
              iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum
              porro a pariatur veniam.
            </p>
            */}
          </div>

          <div className="grid md:grid-cols-3 gap-6 lg:gap-12 text-center">
            <div className="mb-12 md:mb-0">
              <div className="flex justify-center mb-6">
                <img
                  src={pboockvar}
                  className="rounded-full shadow-lg w-32"
                  alt="testimonials pboockvar"
                / >
              </div>
              <h5 className="text-xl font-semibold">Peter Boockvar</h5>
              <div className="mb-2">
                <a href="https://twitter.com/pboockvar" target="_blank" className="face-twitter hover:underline">
                  {twIcon()}@pboockvar
                </a>
              </div>
              <p className="mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="quote-left"
                  className="w-6 pr-2 inline-block"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                  ></path>
                </svg>
                  Always fun chatting the world with Dale. Great questions and wide ranging discussion.
              </p>
            </div>

            {/*=========================*/}
            <div className="mb-12 md:mb-0">
              <div className="flex justify-center mb-6">
                <img
                  src={jackschwager}
                  className="rounded-full shadow-lg w-32"
                  alt="testimonials jackschwager"
                />
              </div>
              <h5 className="text-xl font-semibold">Jack Schwager</h5>
              <div className="mb-2">
                <a href="https://twitter.com/jackschwager" target="_blank" className="face-twitter hover:underline">
                  {twIcon()}@jackschwager
                </a>
              </div>
              <p className="mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="quote-left"
                  className="w-6 pr-2 inline-block"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                  ></path>
                </svg>
                Dale understands markets and asks intelligent questions. I have always enjoyed our interviews.
              </p>
            </div>

            {/*=========================*/}
            <div className="mb-12 md:mb-0">
              <div className="flex justify-center mb-6">
                <img
                  src={petergoodburn}
                  className="rounded-full shadow-lg w-32"
                  alt="testimonials petergoodburn"
                />
              </div>
              <h5 className="text-xl font-semibold">Peter Goodburn</h5>
              <div className="mb-2">
                <a href="https://twitter.com/elliottwave_wti" target="_blank" className="face-twitter hover:underline">
                  {twIcon()}@elliottwave_wti
                </a>
              </div>
              <p className="mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="quote-left"
                  className="w-6 pr-2 inline-block"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                  ></path>
                </svg>
                {trunc("Peter Goodburn")}
              </p>
            </div>

            {/*=========================*/}
            <div className="mb-12 md:mb-0">
              <div className="flex justify-center mb-6">
                <img
                  src={marcmakingsense}
                  className="rounded-full shadow-lg w-32"
                  alt="testimonials marcmakingsense"
                />
              </div>
              <h5 className="text-xl font-semibold">Marc Chandler</h5>
              <div className="mb-2">
                <a href="https://twitter.com/marcmakingsense" target="_blank" className="face-twitter hover:underline">
                  {twIcon()}@marcmakingsense
                </a>
              </div>
              <p className="mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="quote-left"
                  className="w-6 pr-2 inline-block"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                  ></path>
                </svg>
                {trunc("marcmakingsense")}

              </p>
            </div>

            {/*=========================*/}
            <div className="mb-12 md:mb-0">
              <div className="flex justify-center mb-6">
                <img
                  src={stocktonkatie}
                  className="rounded-full shadow-lg w-32"
                  alt="testimonials stocktonkatie"
                />
              </div>
              <h5 className="text-xl font-semibold">Katie Stockton, CMT</h5>
              <div className="mb-2">
                <a href="https://twitter.com/stocktonkatie" target="_blank" className="face-twitter hover:underline">
                  {twIcon()}@StocktonKatie
                </a>
              </div>
              <p className="mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="quote-left"
                  className="w-6 pr-2 inline-block"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                  ></path>
                </svg>
                I love being interviewed by Dale. His matter-of-fact approach to markets resonates with me, and I respect the hard work that goes into his daily analysis.
              </p>
            </div>

            {/*=========================*/}
            <div className="mb-12 md:mb-0">
              <div className="flex justify-center mb-6">
                <img
                  src={fx_button}
                  className="rounded-full shadow-lg w-32"
                  alt="testimonials fx_button"
                / >
              </div>
              <h5 className="text-xl font-semibold">Adam Button</h5>
              <div className="mb-2">
                <a href="https://twitter.com/fx_button" target="_blank" className="face-twitter hover:underline">
                  {twIcon()}@fx_button
                </a>
              </div>
              <p className="mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="quote-left"
                  className="w-6 pr-2 inline-block"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                  ></path>
                </svg>
                {trunc("fx_button")}
              </p>
            </div>

            {/*=========================*/}
            <div className="mb-12 md:mb-0">
              <div className="flex justify-center mb-6">
                <img
                  src={LukeGromen}
                  className="rounded-full shadow-lg w-32"
                  alt="testimonials LukeGromen"
                / >
              </div>
              <h5 className="text-xl font-semibold">Luke Gromen</h5>
              <div className="mb-2">
                <a href="https://twitter.com/LukeGromen" target="_blank" className="face-twitter hover:underline">
                  {twIcon()}@LukeGromen
                </a>
              </div>
              <p className="mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="quote-left"
                  className="w-6 pr-2 inline-block"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                  ></path>
                </svg>
                Dale's interviews feel like conversations with an old friend about markets.  His conversations with guests blend short-term tactical views and intermediate-term strategic viewpoints into useful perspectives for traders.
              </p>
            </div>

            {/*=========================*/}
            <div className="mb-12 md:mb-0">
              <div className="flex justify-center mb-6">
                <img
                  src={hmeisler}
                  className="rounded-full shadow-lg w-32"
                  alt="testimonials hmeisler"
                / >
              </div>
              <h5 className="text-xl font-semibold">Helene Meisler</h5>
              <div className="mb-2">
                <a href="https://twitter.com/hmeisler" target="_blank" className="face-twitter hover:underline">
                  {twIcon()}@hmeisler
                </a>
              </div>
              <p className="mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="quote-left"
                  className="w-6 pr-2 inline-block"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                  ></path>
                </svg>
                It's always interesting when I chat with Dale. He understands markets and the conversations are lively.
              </p>
            </div>

            {/*=========================*/}
            <div className="mb-12 md:mb-0">
              <div className="flex justify-center mb-6">
                <img
                  src={JustinHertzberg}
                  className="rounded-full shadow-lg w-32"
                  alt="testimonials JustinHertzberg"
                />
              </div>
              <h5 className="text-xl font-semibold mb-7">Justin Hertzberg, FPFX</h5>
              <div className="hidden mb-6">
                <a href="https://twitter.com/ForestParkFX" target="_blank" className="face-twitter hover:underline">
                  {twIcon()}@ForestParkFX
                </a>
              </div>
              <p className="mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="quote-left"
                  className="w-6 pr-2 inline-block"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                  ></path>
                </svg>
                {trunc("justin")}
              </p>
            </div>

          </div>

      </section>
    </div>
  )
}
