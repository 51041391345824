import React from "react"
import banner from "../images/contributors-banner.png"

export default function Contributors() {
  return (
    <div className="face-dark-blue-bg mt-0">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-6 lg:px-8 lg:flex lg:items-center">

          <div class="lg:min-w-fit lg:flex-auto face-dark-blue-bg">
              <img class="max-w-full h-auto mx-auto max-h-72" src={banner} alt="Become a sponsor, collaborator" />
          </div>

          <div className="lg:flex-auto sm:ml-0 lg:ml-48 mt-12">
            <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-2xl" id="newsletter-headline">
              Are you interested in being interviewed on F.A.C.E., become a sponsor, or collaborate with us in any way?
            </h2>

            <div className="mt-10 mb-10 sm:flex sm:justify-center lg:justify-start">
                <div>
                    <a
                      href="mailto:info@ForexAnalytix.com?subject=Join the F.A.C.E. cummunity&body="
                      target="_blnak"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium text-white face-blue-bg face-blue-btn-hover md:py-4 md:text-lg md:px-10"
                    >
                      GET IN TOUCH WITH US
                    </a>
                </div>
            </div>
          </div>

      </div>

    </div>
  )
}
