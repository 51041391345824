import React from "react"
import logoFa from "../images/forex-analytix-logo.png"
import logoForestPark from "../images/forest-park-logo.png"
import logoTraderFunding from "../images/trader-funding-program-logo.png"

export default function GridList() {

  return (
    <div className="face-white-bg mb-20 pt-12 sm:pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">

          <div className="max-w-4xl mx-auto">
            <a href="https://www.forexanalytix.com" target="_empty">
              <img
                className="h-40 mx-auto"
                src={logoFa}
                alt="Company name"
              />
            </a>
          </div>

          <p className="mt-3 text-lg text-gray-900 sm:mt-4 text-center">
            Forex Analytix is proud to host the free F.A.C.E. show as a part of its service. Our company’s goal is to <b>educate and help traders be more successful, and F.A.C.E. is a great vehicle for individuals to get ideas, analysis, and market commentary</b>.
            <br/>
            <br/>
            Our range of services include <b>free trading shows, daily analysis, exclusive members-only chatrooms and much more</b> – all delivered via a state-of-the-art platform. We also have dedicated smartphone and tablet apps to make sure that our service is delivered to you wherever you may be.
            <br/>
          </p>
          <div className="mt-10 mb-5 text-xl text-gray-500">
            Sponsored by
          </div>

          <div className="max-w-4xl mx-auto flex justify-center gap-16">
            <a href="https://www.forestparkfx.com/" target="_blank">
              <img
                className="h-20 mx-auto"
                src={logoForestPark}
                alt="ForestPark sponsor"
              />
            </a>

            <a href="https://traderfundingprogram.com/" target="_blank">
              <img
                className="h-20 mx-auto"
                src={logoTraderFunding}
                alt="traderfundingprogram sponsor"
              />
            </a>
          </div>

        </div>
      </div>

    </div>
  )
}
