import React from "react"
import img from "../images/faces-banner-face.jpg"

export default function ImageRightSection() {
  let reversed = true;
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ")
  }
  return (
      <div className="relative py-16 overflow-x-hidden bg-white sm:py-6" id="interviews">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div
            className={classNames(
              reversed ? "order-2" : "",
              "relative sm:py-16 lg:py-0"
            )}
          >
            <div
              aria-hidden="true"
              className={classNames(
                reversed ? "lg:left-0" : "lg:right-0",
                "hidden sm:block lg:absolute lg:w-screen lg:inset-y-0 "
              )}
            >
              <div
                className={classNames(
                  reversed
                    ? "left-1/2 lg:left-72"
                    : "right-1/2 lg:right-72",
                  "absolute inset-y-0 w-full bg-gray-50"
                )}
              />
            </div>
            <div className="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              {/* Testimonial card*/}
              <div className="relative pb-10 overflow-hidden shadow-xl pt-[65.75%]">
                <a href="/interviews">
                  <img
                    className="absolute inset-0 object-cover w-full h-full"
                    src={img}
                    alt="interviews"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-0">
            {/* Content area */}
            <div className="pt-12 sm:pt-16 lg:pt-20">
                <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-gray-900">
                  INTERVIEWS
                </h2>
                <p className="mt-4 text-lg text-gray-900">
                  The interview is a way to present interesting points of view to our public, via guests from a wide range backgrounds and positions.
                  <br/>
                  <br/>
                  Interviewees will make presentations, discuss markets with the host & the ForexAnalytix team, and also take questions from the audience.
                </p>

                <div className="mt-6">
                  <a
                    href="/interviews"
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium shadow-sm text-white face-blue-bg face-blue-btn-hover"
                  >
                    See Upcoming and Past Interviews
                  </a>
                </div>


            </div>


          </div>
        </div>
      </div>
    )
}
