import React from "react"

export default function Cta() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto md:text-center pt-4 pb-12 px-4 sm:px-6 sm:pt-16 lg:pb-16 lg:pt-0 lg:px-8">
        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
          <span className="block">Register today for free</span>
        </h2>
        <div className="mt-8 flex md:justify-center">
          <div className="inline-flex shadow">
            <a
              href="https://www.forexanalytix.com/webinars/face/sign_up"
              target="_blank"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white face-blue-bg face-blue-btn-hover"
            >
              Get Started
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
